export const SYNC = Symbol("SYNC")
export const ASYNC = Symbol("ASYNC")

/** function for creating new action types within the PolicyHub namespace */
// Example: @@opf/user/CHECK_AUTH
export default function actionTypes(typeDefinition, path = `@@opf`) {
  switch (typeDefinition) {
    case SYNC:
      return path

    case ASYNC: {
      const result = { ALL: [] }
      for (let suffix of ["REQUEST", "SUCCESS", "FAILURE"]) {
        const type = `${path}/${suffix}`
        result[suffix] = type
        result["ALL"].push(type)
      }

      return result
    }

    // Assume that typeDefinition is an object
    default: {
      const result = {}
      for (let [key, value] of Object.entries(typeDefinition)) {
        result[key] = actionTypes(value, `${path}/${key}`)
      }

      return result
    }
  }
}
