import { batch } from "react-redux"
import { createAction } from "redux-actions"

import { ANALYTICS, TREES } from "src/globals"
import { api as externalApi } from "../../services/api"
import { createAsyncActionWithContext } from "src/store/utils"
import { selectDocument, fetchFlatTreeDocuments } from "src/store/documents/actions"
import { selectItem, fetchItemBySlug } from "src/store/items/actions"
import { getCurrentStep } from "src/store/ui/selectors"
import selectors from "src/store/selectors"
import types from "./types"

const api = externalApi()

export const toggleLocalAmendmentModalOpen = createAction(types.TOGGLE_LOCAL_AMENDMENT_MODAL)
export const connectionChange = createAction(types.CONNECTION.CHANGE)
export const setShowLocal = createAction(types.SET_SHOW_LOCAL)
export const setAppLoading = createAction(types.SET_APP_LOADING)
export const closeMessage = createAction(types.CLOSE_MESSAGE)
export const clearMessages = createAction(types.CLEAR_MESSAGES)
export const setAppInitStatus = createAction(types.SET_APP_INIT_STATUS)
export const closeFormModal = createAction(types.FORM_MODAL.CLOSE)
export const closeAnonymousMessage = createAction(types.CLOSE_ANONYMOUS_MESSAGE)
export const setFormMode = createAction(types.SET_FORM_MODE)
export const setCurrentStep = createAction(types.SET_CURRENT_STEP)
export const setCurrentIndex = createAction(types.SET_CURRENT_INDEX)
export const nextStep = () => (dispatch, getState) =>
  dispatch(setCurrentStep(getCurrentStep(getState()) + 1))
export const previousStep = () => (dispatch, getState) =>
  dispatch(setCurrentStep(getCurrentStep(getState()) - 1))
export const setSearchText = createAction(types.SET_SEARCH_TEXT)
export const search = createAsyncActionWithContext(types.SEARCH, api.items.search)
export const getSearchResults = createAsyncActionWithContext(
  types.SEARCH_ALL,
  api.search.getSearchResults,
  () => ({ handled: true })
)
export const getSearchFilters = createAsyncActionWithContext(
  types.FETCH_SEARCH_FILTERS,
  api.masterData.getSearchFilters
)
export const clearSearchResults = createAction(types.CLEAR_SEARCH_RESULTS)
export const toggleAffixDrawer = createAction(types.TOGGLE_AFFIX_DRAWER)
export const toggleDisplayDraft = createAction(types.TOGGLE_DISPLAY_DRAFT)
export const toggleMetaDrawer = createAction(types.TOGGLE_META_DRAWER)
export const toggleProcedureDrawer = (payload) => (dispatch, getState) => {
  const state = getState()
  const opfItem = selectors.getCurrentPageItem(state)

  if (!opfItem) return
  const toggleProcedureDrawer =
    payload === true
      ? createAction(types.TOGGLE_PROCEDURE_DRAWER, null, () => ({
          gtm: {
            actionType: ANALYTICS.ITEM_ACTIONS,
            item: opfItem,
            actionTextValue: "changes and ownership open",
          },
        }))
      : createAction(types.TOGGLE_PROCEDURE_DRAWER)

  dispatch(toggleProcedureDrawer(payload))
}

export const openFormModal =
  ({ opfItem, mode, currentIndex, callback, method }) =>
  (dispatch) => {
    const openFormModalAction = createAction(types.FORM_MODAL.OPEN, null, () => ({
      gtm: {
        actionType: ANALYTICS.ITEM_ACTIONS,
        item: opfItem,
        actionTextValue: mode + " - start",
      },
    }))
    batch(() => {
      dispatch(selectItem({ opfItem }))
      dispatch(setFormMode(mode))
      dispatch(setCurrentIndex(currentIndex))
      dispatch(openFormModalAction({ callback, method }))
    })
  }

export const openDocumentFormModal =
  ({ opfDocument, mode }) =>
  (dispatch) => {
    const openFormModalAction = createAction(types.FORM_MODAL.OPEN)
    batch(() => {
      dispatch(selectDocument({ opfDocument }))
      dispatch(setFormMode(mode))
      dispatch(openFormModalAction())
    })
  }
export const trackAnalyticsEvent = (trackingData) => ({
  type: types.ANALYTICS.TRACK_EVENT,
  payload: null,
  meta: { gtm: { ...trackingData } },
})
export const trackPageNotFound = createAction(types.ANALYTICS.TRACK_PAGE_NOT_FOUND)
export const trackException = createAction(types.ANALYTICS.TRACK_EXCEPTION)
export const trackPageRender = createAction(types.ANALYTICS.TRACK_PAGE_RENDER)
export const setCurrentPageItem = createAction(
  types.SET_CURRENT_PAGE_ITEM,
  (payload) => (payload.item ? payload.item : payload),
  (payload) => (payload.timing ? { timing: payload.timing } : null)
)
export const resizeMenu = createAction(
  types.RESIZE_MENU,
  (payload) => payload,
  () => ({ userResized: false })
)
export const userResizeMenu = createAction(
  types.RESIZE_MENU,
  (payload) => payload,
  () => ({ userResized: true })
)
export const restoreMenuSize = createAction(types.RESTORE_MENU_SIZE)
export const toggleCompareView = createAction(types.TOGGLE_COMPARE_VIEW)

export const fetchTreeDataRequest = createAction(types.FETCH_TREE_DATA.REQUEST)
export const fetchTreeDataSuccess = createAction(types.FETCH_TREE_DATA.SUCCESS)
export const fetchTreeDataFailure = createAction(types.FETCH_TREE_DATA.FAILURE)
export const fetchTreeData = () => (dispatch) => {
  dispatch(fetchTreeDataRequest())

  Promise.all([
    dispatch(fetchFlatTreeDocuments({ tree: TREES.POLICIES_STANDARDS })),
    dispatch(fetchFlatTreeDocuments({ tree: TREES.COMMERCIAL_LEGAL })),
    // get the first level of PolicyHub items (Processes layer)
    dispatch(fetchItemBySlug({ level: 1 })),
  ])
    .then(() => {
      dispatch(fetchTreeDataSuccess())
    })
    .catch(() => {
      dispatch(fetchTreeDataFailure())
    })
}
