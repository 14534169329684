import { isEmpty, pick, uniqBy, without } from "lodash"

import { api as externalApi } from "../../services/api"
import { createAction } from "redux-actions"
import { createAsyncActionWithContext } from "../utils"
import selectors from "../selectors"
import types from "./types"
import { batch } from "react-redux"

const api = externalApi()

// composed async action creator function that assigns useprofile to requests
const createAsyncActionWithContextAndProfile =
  (type, asyncFunction) => (payload) => (dispatch, getState) => {
    const userProfile = selectors.userSelectors.getUserProfile(getState())
    const payloadWithUserProfile = payload ? { ...payload, userProfile } : { userProfile }
    return createAsyncActionWithContext(type, asyncFunction)(payloadWithUserProfile)(
      dispatch,
      getState
    )
  }

/** composed action creator function that assigns context and the current working configurator id */
const createAsyncActionWithContextAndConfigurator =
  (type, asyncFunction) => (payload) => (dispatch, getState) => {
    let contextWithConfigurator = null
    if (payload && payload.configuratorId) {
      const { configuratorId, tree = "processes", ...rest } = payload
      payload = rest
      const getConfigurator = selectors.configuratorSelectors.getConfiguratorById(getState())
      const configurator = getConfigurator(configuratorId)
      if (configurator) {
        // set tags to match given configurator if possible
        const configuratorTags = pick(configurator, ["tagA", "tagB", "tagC"])
        contextWithConfigurator = (context) => ({
          ...context,
          user: { ...context.user, ...configuratorTags },
          configuratorId,
          tree,
        })
      } else {
        contextWithConfigurator = (context) => ({ ...context, configuratorId, tree })
      }
    }
    return createAsyncActionWithContext(
      type,
      asyncFunction,
      null,
      contextWithConfigurator
    )(payload)(dispatch, getState)
  }

export const setMostRecentConfigurator = createAction(types.SET_RECENT_CONFIGURATOR)

// ? fetching available claims ready for PolicyHub configuration creation
const {
  getAvailableCountries,
  getAvailableAccounts,
  getAvailableGlobalAccounts,
  getAvailableSites,
} = api.configurator
export const fetchAvailableCountries = createAsyncActionWithContextAndProfile(
  types.FETCH_AVAILABLE_COUNTRIES,
  getAvailableCountries
)
export const fetchAvailableAccounts = createAsyncActionWithContextAndProfile(
  types.FETCH_AVAILABLE_ACCOUNTS,
  getAvailableAccounts
)
export const fetchAvailableGlobalAccounts = createAsyncActionWithContextAndProfile(
  types.FETCH_AVAILABLE_GLOBAL_ACCOUNTS,
  getAvailableGlobalAccounts
)
export const fetchAvailableSites = createAsyncActionWithContextAndProfile(
  types.FETCH_AVAILABLE_SITES,
  getAvailableSites
)

// ? fetching existing PolicyHub configurations
const { getAllGlobalAccounts, getAllCountries, getAllAccounts, getAllSites } = api.levels
export const fetchGlobalAccounts = (api) =>
  createAsyncActionWithContextAndConfigurator(types.FETCH_GLOBAL_ACCOUNTS, getAllGlobalAccounts)
export const fetchCountries = createAsyncActionWithContextAndConfigurator(
  types.FETCH_COUNTRIES,
  getAllCountries
)
export const fetchAccounts = createAsyncActionWithContextAndConfigurator(
  types.FETCH_ACCOUNTS,
  getAllAccounts
)
export const fetchSites = createAsyncActionWithContextAndConfigurator(
  types.FETCH_SITES,
  getAllSites
)

// ? items operations
export const fetchItems = createAsyncActionWithContextAndConfigurator(
  types.FETCH_ITEMS,
  api.items.getItemsForConfigurator
)
export const fetchFullItems = createAsyncActionWithContextAndConfigurator(
  types.FETCH_ITEMS,
  api.items.getItems
)
export const fetchContentRolesForItem = createAsyncActionWithContext(
  types.FETCH_CONTENT_ROLES,
  api.configurator.getContentRolesForItem
)
export const updateOwners = createAsyncActionWithContext(
  types.UPDATE_OWNERS,
  api.configurator.updateContentRoles
)

// ? document operations
export const fetchDocuments = createAsyncActionWithContextAndConfigurator(
  types.FETCH_DOCUMENTS,
  api.documents.getDocumentsForConfigurator
)
export const saveDocumentsForConfigurator = createAsyncActionWithContextAndConfigurator(
  types.SAVE_DOCUMENTS_FOR_CONFIGURATOR,
  api.documents.saveForConfigurator
)

// ? CRUD for configurators
export const updateConfigurator = createAsyncActionWithContextAndConfigurator(
  types.UPDATE_CONFIGURATOR,
  api.configurator.updateConfigurator
)
export const saveItemsForConfigurator = createAsyncActionWithContextAndConfigurator(
  types.SAVE_ITEMS_FOR_CONFIGURATOR,
  api.items.saveForConfigurator
)
export const fetchConfiguratorStatistics = createAsyncActionWithContextAndConfigurator(
  types.FETCH_STATISTICS,
  api.configurator.getConfiguratorStatistics
)
export const fetchConfigurator = createAsyncActionWithContext(
  types.FETCH_CONFIGURATOR,
  api.configurator.getConfigurator
)
export const getByTag = createAsyncActionWithContext(
  types.GET_BY_TAG,
  api.configurator.getByTag,
  null,
  (context, getState, payload) => {
    return {
      ...context,
      user: {
        ...context.user,
        // clear tags from user context and replace with given tags in payload
        tagA: null,
        tagB: null,
        tagC: null,
        ...payload,
      },
    }
  }
)

export const getFilterBy = createAsyncActionWithContext(
  types.GET_FILTER_BY,
  api.configurator.getFilterBy,
  null,
  (context, getState, payload) => {
    if (payload.configurator) {
      const { configurator } = payload
      const { tagA = null, tagB = null, tagC = null, configuratorCountry } = configurator
      const configuratorTags = { tagA, tagB, tagC }
      return {
        ...context,
        user: {
          ...context.user,
          ...configuratorTags,
        },
        configuratorId: configurator.id,
        configuratorType: configurator.configuratorType,
        configuratorCountry,
        mode: "refiner",
      }
    }
    return context
  }
)

// ? create a new configurator (override application context settings with values from the form)
export const createConfigurator = createAsyncActionWithContext(
  types.CREATE_CONFIGURATOR,
  api.configurator.createConfigurator,
  null,
  (context, getState, payload) => {
    if (payload.configurator) {
      const { configurator } = payload
      const { tagA = null, tagB = null, tagC = null } = configurator
      const configuratorTags = { tagA, tagB, tagC }
      return {
        // apply values from form
        ...context,
        user: {
          ...context.user,
          ...configuratorTags,
        },
        configuratorId: null,
        configuratorType: payload.configurator.configuratorType,
      }
    }
    return context
  }
)

export const fetchUserConfigurators = createAsyncActionWithContextAndProfile(
  types.FETCH_USER_CONFIGURATORS,
  api.configurator.getUserConfigurators
)

export const fetchConfiguratorDataForCurrentRole = (role) => (dispatch, getState) => {
  batch(() => {
    dispatch(fetchCountries())
    dispatch(fetchSites())
    dispatch(fetchAccounts())
    dispatch(fetchGlobalAccounts())
  })
}

export const saveCheckedDocumentsConfiguration =
  ({ checkedKeys, allTreeItems, configuratorId }) =>
  (dispatch, getState) => {
    const itemsToAdd = allTreeItems.filter((item) => checkedKeys.includes(item.opfId))
    const getParentDocuments = selectors.configuratorSelectors.getParentDocuments(getState())
    // const allCategories = allTreeItems.filter(item => item.type === POLICIES_STANDARDS_CATEGORY)
    // const allDocuments = allTreeItems.filter(item => [POLICY, STANDARD].includes(item.type))

    const parentsOfDocumentsToAdd = uniqBy(itemsToAdd.flatMap(getParentDocuments), "opfId")
    const allItemsToAdd = [...itemsToAdd, ...parentsOfDocumentsToAdd]
    const idsToAdd = allItemsToAdd.map((item) => item.opfId)

    const uncheckedItems = without(allTreeItems, ...itemsToAdd)
    const parentsOfDocumentsToRemove = uncheckedItems.flatMap(getParentDocuments)
    const itemsToRemove = [...uncheckedItems, ...parentsOfDocumentsToRemove]
    const allItemsToRemove =
      itemsToRemove.filter((opfDocument) => !idsToAdd.includes(opfDocument.opfId)) || []

    const itemsWithConfiguratorRemoved = uniqBy(allItemsToRemove, "opfId")
      .filter(
        (opfDocument) =>
          isEmpty(opfDocument.configurators) === false &&
          opfDocument.configurators.includes(configuratorId)
      )
      .map((opfDocument) => ({
        ...opfDocument,
        configurators: opfDocument.configurators.filter(
          (existingConfiguratorId) => existingConfiguratorId !== configuratorId
        ),
      }))

    const itemsWithConfiguratorAdded = uniqBy(allItemsToAdd, "opfId")
      .filter((opfDocument) => !opfDocument.configurators.includes(configuratorId))
      .map((opfDocument) => ({
        ...opfDocument,
        configurators: [...opfDocument.configurators, configuratorId],
      }))

    console.info("add to configurator:", itemsWithConfiguratorAdded)
    console.info("remove from configurator:", itemsWithConfiguratorRemoved)

    return dispatch(
      saveDocumentsForConfigurator({
        opfDocuments: [...itemsWithConfiguratorAdded, ...itemsWithConfiguratorRemoved],
        configuratorId,
      })
    )
  }

export const saveCheckedItemConfiguration =
  ({ checkedKeys, allTreeItems, configuratorId }) =>
  (dispatch, getState) => {
    const calculateItemsToUpdate = () => {
      const itemsToAdd = allTreeItems.filter((item) => checkedKeys.includes(item.opfId)) // all checked items
      const getParentItems = selectors.configuratorSelectors.getParentItems(getState())
      // find parents for updating items
      const parentsForItemsToAdd = uniqBy(itemsToAdd.flatMap(getParentItems), "opfId") // unchecked parent item with checked children
      const allItemsToAdd = [...itemsToAdd, ...parentsForItemsToAdd]
      const idsToAdd = allItemsToAdd.map((item) => item.opfId)

      const uncheckedItems = without(allTreeItems, ...itemsToAdd) // unchecked items in tree
      // const parentsForItemsToRemove = uncheckedItems.flatMap(getParentItems) // all parent items for items to remove
      // const itemsToRemove = [...uncheckedItems, ...parentsForItemsToRemove] // items to remove
      const itemsToRemove = [...uncheckedItems] // items to remove
      const allItemsToRemove = itemsToRemove.filter((item) => !idsToAdd.includes(item.opfId)) || [] // exclude items to add from removal

      // remove current configurator from all items marked for removal
      const itemsWithConfiguratorRemoved = uniqBy(allItemsToRemove, "opfId")
        .filter(
          (item) =>
            item.level !== 1 &&
            isEmpty(item.configurators) === false &&
            item.configurators.includes(configuratorId)
        )
        .map((item) => ({
          ...item,
          configurators: item.configurators.filter(
            (existingConfiguratorId) => existingConfiguratorId !== configuratorId
          ),
        })) // remove configurator

      // add current configurator to all items marked for addition
      const itemsWithConfiguratorAdded = allItemsToAdd
        .filter((item) => !item.configurators.includes(configuratorId)) // exclude unchanged items
        .map((item) => ({ ...item, configurators: [...item.configurators, configuratorId] })) // add configurator

      console.info("add to configurator:", itemsWithConfiguratorAdded)
      console.info("remove from configurator:", itemsWithConfiguratorRemoved)

      const updatedItems = [...itemsWithConfiguratorAdded, ...itemsWithConfiguratorRemoved]
      return updatedItems
    }

    // return promise immediately and wait for next tick before calculating items to send and update
    // this is to prevent the UI from freezing when clicking "Save and return" in the UI
    return new Promise((resolve, reject) => {
      window.setTimeout(() => {
        const updatedItems = calculateItemsToUpdate()
        return dispatch(saveItemsForConfigurator({ opfItems: updatedItems, configuratorId })).then(
          resolve,
          reject
        )
      })
    })
  }
