import {
  addItemAndUpdateParent,
  arrayToSlugObject,
  byOrder,
  flattenOpfItems,
  getSlug,
  mergeOpfItems,
  removeItemAndUpdateParentChildren,
  updateItem,
} from "./utils"
import { get, mergeWith, uniq, without } from "lodash"

import { EMPTY_OPF_ITEM } from "../../globals"
import configuratorTypes from "../configurator/types"
import { getItemById } from "./selectors"
import localeTypes from "../locale/types"
import { produce } from "immer"
import types from "./types"
import uiTypes from "../ui/types"
import userTypes from "../user/types"

export const initialState = Object.freeze({
  allItems: [],
  allNonLeafItems: [],
  hasFetchedTreeData: false,
  bySlug: {},
  allFavorites: [],
  initialLoadForContext: false,
  firstLevelIsLoading: true,
  auditContinuationToken: null,
  allAudits: [],
  auditsBySlug: {},
  selectedItem: null,
  procedureEditMode: false,
})

export default function reducer(state = initialState, action) {
  return produce(state, (items) => {
    // slug from action neta
    const requestedSlug = get(action, "meta.request.opfItem.slug", null)
    switch (action.type) {
      case types.FETCH_ICONS.SUCCESS:
        items.icons = action.payload.folders.reduce((accum, category, index) => {
          const { name = index, icons } = category
          return { ...accum, [name]: icons }
        }, {})
        break

      case uiTypes.FORM_MODAL.CLOSE:
        items.selectedItem = null
        break

      case types.SET_CURRENT_PAGE_ITEM:
        items.currentPageItem = action.payload
        break

      case types.TOGGLE_PROCEDURE_EDIT_MODE:
        items.procedureEditMode = action.payload
        break

      case types.FETCH_ITEMS.REQUEST: // ? if the requested item is an empty PolicyHub item that means that the first level of the PolicyHub is being requested
        items.firstLevelIsLoading = action.payload.opfItem === EMPTY_OPF_ITEM
        break

      case types.FETCH_ITEM_BY_SLUG.SUCCESS:
      case types.FETCH_ITEMS.SUCCESS: // adds fetched items to store and merges with existing collection
        return addItems(action, items)

      case types.FETCH_ALL_NON_LEAF_ITEMS.REQUEST:
        items.hasFetchedTreeData = false
        break

      case types.FETCH_ALL_NON_LEAF_ITEMS.SUCCESS:
        items.allNonLeafItems = action.payload.nodes
        items.hasFetchedTreeData = true
        break

      case types.FETCH_ALL_NON_LEAF_ITEMS.FAILURE:
        items.hasFetchedTreeData = false
        break

      case types.UPDATE_ITEM.SUCCESS:
      case types.CREATE_ITEM_VARIANT.SUCCESS:
      case types.TRANSLATE_ITEM.SUCCESS:
      case types.LOCK_ITEM.SUCCESS:
      case types.UNLOCK_ITEM.SUCCESS:
      case types.PUBLISH_ITEM.SUCCESS:
        return updateItem(action.payload.opfItem, items)

      case types.CREATE_ITEM.SUCCESS:
      case types.CREATE_ITEM_FROM_WORD.SUCCESS:
      case types.CREATE_ITEM_DRAFT.SUCCESS:
        return addItemAndUpdateParent(action.payload.opfItem, items)

      case types.ARCHIVE_ITEM.SUCCESS:
      case types.REMOVE_ITEM_FROM_CONFIGURATOR.SUCCESS:
        return archiveItem(action, items)

      case types.MOVE_ITEM.SUCCESS:
        return moveItem(action, items)

      case types.REORDER_ITEMS.SUCCESS:
        return reorderItems(action, items)

      case configuratorTypes.SAVE_ITEMS_FOR_CONFIGURATOR.SUCCESS: // ? clear items if any changes made in configurator to an existing configurator
      case localeTypes.SET_LANGUAGE: // ? clear items state when configurator or language is changed
      case userTypes.FETCH_CONFIGURATOR.SUCCESS:
        return clearItems(items)

      case types.SERVICELINES.FETCH.SUCCESS:
        items.serviceLines = action.payload.serviceLines
        break

      case types.AUDITS.FETCH.SUCCESS: // add audits for request item to collection
        items.auditsBySlug[requestedSlug] = action.payload.audits
        break

      case types.AUDITS.FETCH_ALL.SUCCESS:
        return addToAllAudits(action, items)

      case types.AUDITS.CLEAR:
        items.auditContinuationToken = null
        items.allAudits = []
        break

      case types.AUDITS.SET_TOKEN: // set continuation token to allow for pagination through api
        items.auditContinuationToken = action.paylod
        break

      case types.SELECT_ITEM: // used to set a target for form modals etc.
        items.selectedItem = action.payload.opfItem
        break

      case types.CLEAR_SELECTED_ITEM:
        items.selectedItem = null
        break

      case types.FAVORITES.FETCH.SUCCESS:
        return addFavorites(action, items)

      case types.FAVORITES.ADD.REQUEST: // adds attempted item to favorites optimistically 🌟
      case types.FAVORITES.REMOVE.FAILURE: // add item back to favorites if removal api call failed 🚫
        items.allFavorites.push(requestedSlug)
        break

      case types.FAVORITES.REMOVE.REQUEST: // remove item from favorites optimistically before calling api
      case types.FAVORITES.ADD.FAILURE: // remove item from favorites if the api call to add it failed 🚫
        items.allFavorites = without(items.allFavorites, requestedSlug)
        break

      default:
        return items
    }
    return items
  })
}

function clearItems(items) {
  return {
    ...items,
    ...initialState,
    allFavorites: items.allFavorites,
    allAudits: items.allAudits,
    auditContinuationToken: items.auditContinuationToken,
    initialLoadForContext: false,
  }
}

function addToAllAudits(action, items) {
  const updatedAudits = items.allAudits.map((audit) => {
    const updatedAudit = action.payload.audits.find((newAudit) => newAudit.id === audit.id)
    return updatedAudit || audit
  })
  const newAudits = without(action.payload.audits, ...updatedAudits)
  items.allAudits = [...newAudits, ...updatedAudits]
  items.auditContinuationToken = action.payload.continuationToken
  return items
}

function addFavorites(action, items) {
  const favorites = action.payload.opfItems
  const favoriteSlugs = favorites.filter((item) => item).map(getSlug)
  const favoriteItems = arrayToSlugObject(favorites)
  items.bySlug = mergeWith(items.bySlug, favoriteItems, mergeOpfItems)
  items.allFavorites = favoriteSlugs
  return items
}

function archiveItem(action, items) {
  const originalItem = action.meta.request.opfItem
  const archivedItemFallback = action.payload.opfItem
  // updates the existing item with the fallback data provided by the API
  if (archivedItemFallback) return updateItem(archivedItemFallback, items)
  // if there is no fallback data after archiving - simply remove the archived item from the collection
  return removeItemAndUpdateParentChildren(originalItem, items)
}

function addItems(action, items) {
  const { opfItem } = action.meta.request // requested PolicyHub item
  const { opfItems } = action.payload // received results
  const firstLevelRequested = opfItem === EMPTY_OPF_ITEM // if the requested item was empty - the api will return the first level of the opf
  // normalize item structure for state
  const flattenedItems = flattenOpfItems(opfItems)
  const flattenedSlugStructure = arrayToSlugObject(flattenedItems)
  const newItemSlugs = flattenedItems.map((item) => item.slug)
  // update values
  if (firstLevelRequested) {
    items.firstLevelIsLoading = firstLevelRequested ? false : items.firstLevelIsLoading
    if (items.initialLoadForContext === false) {
      items.initialLoadForContext = true
    }
  }
  items.allItems = uniq([...items.allItems, ...newItemSlugs]) // update list of item slugs
  items.bySlug = mergeWith(items.bySlug, flattenedSlugStructure, mergeOpfItems) // merge updated items with existing collection
  return items
}

function reorderItems(action, items) {
  const { opfItems } = action.payload
  const [item] = opfItems // first item
  const parentItem = getItemById({ items })(item.parentId) // use first item to get parent id for all updated children
  parentItem.opfChildren = opfItems.sort(byOrder) // sort parent item children after new order
  items.bySlug = mergeWith(items.bySlug, arrayToSlugObject(opfItems), mergeOpfItems) // update reordered item properties
  return updateItem(parentItem, items)
}

export const moveItem = (action, items) => {
  const originalItem = action.meta.request.opfItem
  const newItem = action.payload.opfItem

  items = removeItemAndUpdateParentChildren(originalItem, items)
  items.allItems = uniq([...items.allItems, newItem.slug])
  items.bySlug = { ...items.bySlug, [newItem.slug]: newItem }

  items = addItemToNewParent(newItem, items)

  return items
}

export const addItemToNewParent = (itemToBeMoved, items) => {
  const { bySlug } = items
  const parentItem = Object.values(bySlug).find((item) => {
    return item.opfId === itemToBeMoved.parentId
  })

  if (parentItem) {
    bySlug[parentItem.slug].opfChildren.push(itemToBeMoved)
  }

  return items
}
