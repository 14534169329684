import * as configuratorSelectors from "./configurator/selectors"
import * as documentsSelectors from "./documents/selectors"
import * as itemSelectors from "./items/selectors"
import * as localeSelectors from "./locale/selectors"
import * as refinerSelectors from "./refiner/selectors"
import * as uiSelectors from "./ui/selectors"
import * as userSelectors from "./user/selectors"
import * as configsSelectors from "./configs/selectors"
import * as draftsSelectors from "./drafts/selectors"
import * as homeSelectors from "./home/selectors"
import * as newsSelectors from "./news/selectors"
import * as opfCommunitySelectors from "./opfCommunity/selectors"
import * as certificationSelectors from "./certifications/selectors";
import * as uniqNumberSelectors from "./uniqNumberGenerator/selectors";
import * as statisticsSelectors from "./statistics/selectors";

import { isEmpty, pick, startCase, toLower } from "lodash"

import countries from "i18n-iso-countries"
import { createSelector } from "reselect"

const removeFileExtension = (fileName) =>
  fileName.includes(".") ? fileName.split(".").slice(0, -1).join(".") : fileName

// ? 🔑 application context, used for network requests to the PolicyHub Api
const getApplicationContext = createSelector(
  userSelectors.getUser,
  localeSelectors.getCurrentLanguage,
  localeSelectors.getDefaultLanguage,
  userSelectors.getCurrentConfigurator,
  refinerSelectors.getRefinerEnabled,
  refinerSelectors.getRefinerSettingsApplied,
  refinerSelectors.getAppliedRefinerSettings,
  (
    user,
    language,
    defaultLanguage,
    configurator,
    refinerEnabled,
    refinerApplied,
    refinerSettings
  ) => {
    const userContext = pick(user, ["id", "email", "name", "upn"])
    const useRefinerContext = refinerEnabled && refinerApplied && refinerSettings
    // if refiner is enabled: use refiner settings for application context instead of user details
    const currentRole = useRefinerContext
      ? refinerSettings.role
      : user.currentRole
      ? user.currentRole.name
      : null
    const currentTagA = useRefinerContext ? refinerSettings.tagA : user.currentTagA
    const currentTagB = useRefinerContext ? refinerSettings.tagB : user.currentTagB
    const currentTagC = useRefinerContext ? refinerSettings.tagC : user.currentTagC
    return {
      user: {
        tagA: currentTagA,
        tagB: currentTagB,
        tagC: currentTagC,
        role: currentRole,
        ...userContext,
      },
      language,
      configuratorId: configurator ? configurator.id : null,
      configuratorType: configurator ? configurator.configuratorType : null,
      configuratorCountry: configurator ? configurator.configuratorCountry : null,
      fallbackLanguage: defaultLanguage,
      mode: useRefinerContext ? "refiner" : "default",
      tree: "processes",
    }
  }
)

/** Generate a media link with application context parameters for the current user settings. */
const toMediaLink = createSelector(getApplicationContext, (applicationContext) => (link) => ({
  ...link,
  name: link.title
    ? link.title
    : link.name && link.name.includes(".")
    ? removeFileExtension(link.name)
    : link.name,
  uid: link.uid ? link.uid : removeFileExtension(link.url),
  url: toLower(
    `/media/${applicationContext.language}/${applicationContext.user.tagA || "-"}/${
      applicationContext.user.tagB || "-"
    }/${applicationContext.user.tagC || "-"}${link.url}`
  ),
}))

const mapToMediaLinks = (state) => (links) =>
  links
    ? links
        .filter((link) => !isEmpty(link.url))
        .filter((link) => link.url !== "")
        .map(toMediaLink(state))
    : []

const getUserTrackingContext = createSelector(
  getApplicationContext,
  userSelectors.getUser,
  localeSelectors.getCurrentLanguage,
  localeSelectors.getLanguages,
  itemSelectors.getItemTagName,
  (applicationContext, user, currentLanguageCode, languages, getItemTagName) => {
    const { tagA, tagB, tagC } = applicationContext.user
    const userTagA = countries.isValid(tagA)
      ? countries.getName(tagA, "en")
      : tagA === undefined
      ? null
      : getItemTagName(tagA)
    const userTagB = tagB === undefined ? null : getItemTagName(tagB)
    const userTagC = tagC === undefined ? null : getItemTagName(tagC)
    const language = languages.find((language) => language.code === currentLanguageCode)
    const isTestUser = /^testuser/.test(user.upn)
    return {
      userConfiguratorType:
        user.configuratorType === "N/A" ? "global" : startCase(user.configuratorType),
      userId: user.userId,
      userTagA,
      userTagB,
      userTagC,
      userRole: user.currentRole.name,
      testUser: isTestUser,
      userLanguage: language && language.name ? language.name : currentLanguageCode,
    }
  }
)

const getCurrentPageItem = (state) =>
  itemSelectors.getItemBySlug(state)(state.ui.currentPageItem) ||
  documentsSelectors.getDocumentBySlug(state)(state.ui.currentPageItem)

const getCurrentPageItemTrackingContext = createSelector(
  getCurrentPageItem,
  localeSelectors.getLanguages,
  refinerSelectors.getRefinerEnabled,
  refinerSelectors.getRefinerSettingsApplied,
  itemSelectors.getItemTagName,
  (pageItem, languages, refinerEnabled, refinerApplied, getItemTagName) => {
    if (!pageItem) return
    const itemTagA = pageItem.tagA
      ? countries.isValid(pageItem.tagA)
        ? countries.getName(pageItem.tagA, "en")
        : getItemTagName(pageItem.tagA)
      : null
    const itemTagB = getItemTagName(pageItem.tagB)
    const itemTagC = getItemTagName(pageItem.tagC)
    const itemLanguage = languages.find((language) => language.code === pageItem.language)
    const refinedView = refinerEnabled && refinerApplied

    // const getItemType = () => {
    //   if (pageItem.global === false) return pageItem.variantId !== null ? "local variant" : "local"
    //   else return pageItem.variantId !== null ? "variant" : "global"
    // }

    const getItemType = () => {
      return pageItem.global === false ? "local" : "global"
    }

    return {
      itemId: pageItem.opfId,
      itemSlug: pageItem.slug,
      itemTitle: pageItem.title,
      itemLocal: pageItem.global === false,
      itemCategoryType: pageItem.type,
      itemType: getItemType(),
      itemTagA,
      itemTagB,
      itemTagC,
      refinedView,
      itemLanguage: itemLanguage ? itemLanguage.name : null,
    }
  }
)

const selectors = {
  getApplicationContext,
  toMediaLink,
  mapToMediaLinks,
  userSelectors,
  localeSelectors,
  uiSelectors,
  itemSelectors,
  refinerSelectors,
  configuratorSelectors,
  documentsSelectors,
  getCurrentPageItem,
  getUserTrackingContext,
  getCurrentPageItemTrackingContext,
  configsSelectors,
  draftsSelectors,
  homeSelectors,
  newsSelectors,
  opfCommunitySelectors,
  certificationSelectors,
  uniqNumberSelectors,
  statisticsSelectors,
}

export default selectors
