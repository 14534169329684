import { isArray, isEmpty, pick } from "lodash"

import { OPF_ITEM_TYPES } from "../../globals"
import { getItemById } from "./selectors"

const { PROCEDURE_ELEMENT } = OPF_ITEM_TYPES

export const removeKey = (key, { [key]: _, ...rest }) => rest
export const byOrder = (a, b) => a.order - b.order
export const getOpfItemIdentifiers = (opfItem) =>
  Object.values(pick(opfItem, ["slug", "opfId", "id"]))
export const getSlug = (item) => item.slug
/** if an item has values in it's step property - add parentSlug as prop to each step  */
export const addParentIdentifiersToProcedureStep = (opfItem) =>
  opfItem.steps && opfItem.steps.length
    ? {
        ...opfItem,
        steps: opfItem.steps.map((step) => ({
          ...step,
          parentSlug: opfItem.slug,
          parentId: opfItem.opfId,
          type: PROCEDURE_ELEMENT,
        })),
      }
    : opfItem
/**
 * @example ```js
 * normalizeToSlugObject({ slug: 'example/slug', ... })
 *  -> { 'example/slug/': { slug: 'example/slug', ... } } ```
 * @param {*} item
 */
export const normalizeToSlugObject = (item) =>
  item ? { [item.slug]: addParentIdentifiersToProcedureStep(item) } : null
export const toSlugObject = (accum, value) => ({ ...accum, ...normalizeToSlugObject(value) })
export const arrayToSlugObject = (opfItems) => opfItems.reduce(toSlugObject, {})

/** merge PolicyHub items and prefer the new values if they have opfChildren */
export const mergeOpfItems = (objValue, srcValue) => {
  if (objValue) {
    let mergedValue = srcValue
    if (isEmpty(srcValue.opfChildren) && !isEmpty(objValue.opfChildren)) {
      mergedValue = { ...mergedValue, opfChildren: objValue.opfChildren }
    }
    if (objValue.draftVersion && srcValue.draftVersion === null) {
      mergedValue = { ...mergedValue, draftVersion: objValue.draftVersion }
    }
    if (isEmpty(objValue.availableActions) === false && isEmpty(srcValue.availableActions)) {
      mergedValue = { ...mergedValue, availableActions: objValue.availableActions }
    }
    return { ...objValue, ...mergedValue }
  }
  return srcValue
}

/** recursively go through PolicyHub structure and generate array */
const getOpfChildrenRecursively = (item) => {
  if (!isEmpty(item.opfChildren)) {
    return [{ ...item, hasChildren: true }, ...item.opfChildren.flatMap(getOpfChildrenRecursively)]
  }
  return [item]
}

/** flatten a collection of PolicyHub items recursively */
export const flattenOpfItems = (items) => {
  // flattens collection of PolicyHub items
  items = isArray(items) ? items : [items]
  return items.flatMap(getOpfChildrenRecursively)
}

/** return updated item with property indicating whether or not it has children */
export const applyHasChildrenProperty = (item) => ({
  ...item,
  hasChildren: !isEmpty(item.opfChildren),
})

/** get the parent item of the given item from the store */
const getParentItem = (item, items) => getItemById({ items })(item.parentId)

/** updates the value of an PolicyHub item and the values of it's parent's children  🔄   */
export const updateItem = (item, items) => {
  items.itemBeingUpdated = null
  items.bySlug[item.slug] = addParentIdentifiersToProcedureStep(item)
  return items
}

/** add a new item to the store and update it's parent's child array by adding the given item to it */
export const addItemAndUpdateParent = (item, items) => {
  items = updateItem(item, items)
  const parentItem = getParentItem(item, items)
  if (parentItem === undefined) return items
  parentItem.opfChildren.push(item)
  parentItem.opfChildren = parentItem.opfChildren.sort(byOrder)
  return updateItem(parentItem, items)
}

/** remove an item from the store and update it's parent's child array by removed the given item from it */
export const removeItemAndUpdateParentChildren = (item, items) => {
  const itemIdentifiers = getOpfItemIdentifiers(item) // get id and slug for PolicyHub item to remove
  const allItemsWithoutRemoved = items.allItems.filter(
    (identifier) => !itemIdentifiers.includes(identifier)
  ) // removal of archived item
  const parentItem = getParentItem(item, items)
  items.bySlug = removeKey(item.slug, items.bySlug)
  items.allItems = allItemsWithoutRemoved
  // finish updating if there is no parent item
  if (parentItem === undefined) return items
  // update item parent's child collection to reflect changes made to it's child item
  parentItem.opfChildren = parentItem.opfChildren
    .filter((child) => child && child.slug !== item.slug)
    .sort(byOrder)

  // update parent item with new changes
  return updateItem(parentItem, items)
}
